import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './InfoHeader.css';
import LightIndicator from '../LightIndicator/LightIndicator';
import AlarmIcon from '../common/AlarmIcon/AlarmIcon';
import { LicensePermissions } from '../LicensePermissions/LicensePermissions';
import { LicenseType } from '../LicenseType/LicenseType';
import config from '../../dashboard_config.json';

class InfoHeader extends React.PureComponent {
    fadeTime = 200;
    uniqueId = `${this.props.type}-${this.props.id}-card-icons`;

    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
    }

    componentDidMount() {
        $(`#${this.uniqueId}`).fadeOut(0);
        this.addDisconnectedOutputTooltip();
    }

    componentDidUpdate(prevProps) {
        const { isBroadcasting, outputAddressId } = this.props;
        if (
            isBroadcasting !== prevProps.isBroadcasting ||
            outputAddressId !== prevProps.outputAddressId
        ) {
            if (isBroadcasting === true && outputAddressId !== null) {
                this.addDisconnectedOutputTooltip();
            } else {
                $(`#${this.props.name}`).tooltip('hide');
            }
        }
    }

    addDisconnectedOutputTooltip() {
        $(`#${this.props.name}`).tooltip({
            title: 'This source is not connected to an output',
        });
    }

    openSettingDisplayedNameModal = () => {
        const { type, id, primaryId, name, displayedName, isDesignatedOutput, uuid } = this.props;
        const selectedId = type === 'Primary' ? primaryId : id; // Use primaryId for Primary type and id for Output type
        this.props.setDetailsForModal(type, selectedId, name, displayedName, isDesignatedOutput, uuid);
        $('#setting-displayed-name-modal').modal();
    };

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
        $(`#${this.uniqueId}`).fadeIn(this.fadeTime);
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
        $(`#${this.uniqueId}`).fadeOut(this.fadeTime);
    };

    openShareModal = () => {
        this.props.setShareId(this.props.primaryId)
        $('#share-link-modal').modal('show')
    }

    openDeleteModal = () => {
        const primaryNameToDelete = this.props.displayedName || this.props.name;
        this.props.setPrimaryToDelete(this.props.primaryId, primaryNameToDelete)
        $('#delete-primary-modal').modal('show')
    }

    render() {
        const {
            type,
            name,
            displayedName,
            isActive,
            isBroadcasting,
            isThereIssue,
            uuid,
            screenSize,
            handleGuidCopyIconClick,
            outputAddressId,
            daysToExpire,
            companyType,
            licensePermission,
            licensePermissionId,
            licenseTypeId
        } = this.props;

        const { licenseGracePeriodTime } = config.dashboard.license;
        const { allowDeleteGuest } = config.dashboard.guests;

        let expiration;
        let expirationMessage = '';
        let formattedLicensePermissionsName;

        if (licenseTypeId === LicenseType.DAILY) {
            expiration = daysToExpire;
        } else if (licensePermissionId === LicensePermissions.FREE_TRIAL ||
            licensePermissionId === LicensePermissions.BASIC ||
            licensePermissionId === LicensePermissions.LIVE_OMNI ||
            licensePermissionId === LicensePermissions.GUEST) {
            expiration = daysToExpire;
        } else { // Add 3 days grace preiod time only for paid licenses as in centralserver
            expiration = daysToExpire + licenseGracePeriodTime;
        }

        if (licensePermission) {
            formattedLicensePermissionsName = licensePermission
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        } else {
            formattedLicensePermissionsName = '';
        }

        if (expiration < 0) {
            expirationMessage = `Your ${formattedLicensePermissionsName} License has expired. Please subscribe to regain access to all Omnistream features`;
        } else if (expiration <= 10) {
            const days = Math.floor(expiration) + 1;
            expirationMessage = `Your ${formattedLicensePermissionsName} License will expire in ${days} ${days <= 1 ? 'day' : 'days'}`;
        }

        return (
            <div
                className="info-header d-flex justify-content-between align-items-center"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <span className="d-flex align-items-center">
                    <span className="mr-auto">{displayedName ? displayedName : name}</span>
                    <span id={this.uniqueId}>
                        {type === 'Primary' && this.state.isHovered && (
                            <Fragment>
                                <i
                                    className="primary-name-edit-icon material-icons"
                                    onClick={this.openSettingDisplayedNameModal}
                                >
                                    edit
                                </i>

                                {licensePermissionId === LicensePermissions.GUEST && (
                                    <>
                                        <i
                                            className="primary-name-edit-icon material-icons"
                                            onClick={this.openShareModal}
                                        >
                                            share
                                        </i>
                                        {allowDeleteGuest && (
                                            <i
                                                className="primary-name-edit-icon material-icons"
                                                onClick={this.openDeleteModal}
                                            >
                                                delete
                                            </i>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        )}
                        {type === 'Output' && (
                            <Fragment>
                                <i
                                    className="output-settings-icon material-icons"
                                    onClick={this.openSettingDisplayedNameModal}
                                >
                                    settings_applications
                                </i>
                                <i
                                    className="guid-copy-icon material-icons ml-2"
                                    onClick={() => handleGuidCopyIconClick(uuid)}
                                >
                                    file_copy
                                </i>
                            </Fragment>
                        )}
                    </span>
                </span>
                <span className="status-icon mr-1 d-flex align-items-center">
                    {type === 'Primary' && (
                        <Fragment>
                            {companyType === 0 && (
                                <div
                                    hidden={isBroadcasting === false || outputAddressId !== null}
                                >
                                    <AlarmIcon
                                        position="relative"
                                        tooltipDirection="down"
                                        alarmTooltipText="This source is not connected to an output"
                                        alarmIcon="broken_image"
                                        criticalLevel={0}
                                    />
                                </div>
                            )}
                            <div
                                hidden={isNaN(daysToExpire) || daysToExpire > 10}
                            >
                                <AlarmIcon
                                    position="relative"
                                    tooltipDirection="down"
                                    alarmTooltipText={expirationMessage}
                                    alarmIcon="access_time"
                                    isIconBold={true}
                                    criticalLevel={daysToExpire < 0 ? 2 : 0}
                                />
                            </div>
                        </Fragment>
                    )}
                    <LightIndicator
                        type={type}
                        isActive={isActive}
                        isBroadcasting={isBroadcasting}
                        isThereIssue={isThereIssue}
                        screenSize={screenSize}
                    />
                </span>
            </div>
        );
    }
}

InfoHeader.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayedName: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    isBroadcasting: PropTypes.bool,
    isThereIssue: PropTypes.bool,
    uuid: PropTypes.string,
    screenSize: PropTypes.string,
    handleGuidCopyIconClick: PropTypes.func,
    outputAddressId: PropTypes.string,
    daysToExpire: PropTypes.number,
    companyType: PropTypes.number,
    setDetailsForModal: PropTypes.func.isRequired,
};

export default InfoHeader;
