import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EXPORT_APIS } from '../../constants/endPointsURLs';
import Toggle from 'react-toggle';
import axios from 'axios';
import Carousel from '../common/Carousel/Carousel';
import config from '../../dashboard_config.json';
import { isExistUser } from '../../utils/codecHandlers';
import './Export.css';

const common_export_icon = './images/common_export_icon.svg';
const facebookIcon = './images/facebook_icon.svg';
const youtubeIcon = './images/youtube_icon.svg';
const omniPlayerIcon = './images/omni_player_icon.svg';
const twitchIcon = './images/twitch_icon.svg';
const telegramIcon = './images/telegram_icon.svg';
const instagramIcon = './images/instagram_icon.svg';

// const SOURCES = ['video', 'recipent'];

const {
  showAmsExportsAndUseLogic,
  keepAmsExports,
  useAmsExportConvertAndLogic,
  enableOmniSrtH265Mode,
  amsPlayerPlayOrderProtocolType
} = config.dashboard.exports.amsExportsControl;

export const EXPORT_METHODS = [
  showAmsExportsAndUseLogic
  ? {
    id: 'OmnistreamLivePlayerRTMP',
    name: 'LIVE OMNI - RTMP',
    isCreated: true,
    type: 7,
    fields: [
      { id: 'url', name: 'Streaming URL', disabled: true, required: false },
      { id: 'key', name: 'Streaming Key', disabled: true, required: false },
    ],
  } : null,
  {
    id: 'RTMP',
    name: 'Custom RTMP',
    isCreated: true,
    type: 0,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
  showAmsExportsAndUseLogic
  ? {
    id: 'OmnistreamLivePlayerSRT',
    name: 'LIVE OMNI - SRT',
    isCreated: true,
    type: 8,
    fields: [
      { id: 'host', name: 'Stream host', disabled: true, required: false },
      { id: 'port', name: 'Port', disabled: true, required: false },
      {
        id: 'exportStreamId',
        name: 'Stream ID',
        disabled: true,
        required: false,
      },
    ],
  } : null,
  {
    id: 'SRTListener',
    name: 'SRT Listener',
    isCreated: true,
    type: 2,
    fields: [
      { id: 'host', name: 'Stream host', disabled: true, required: false },
      { id: 'port', name: 'Port', disabled: true, required: false },
      { id: 'key', name: 'Passphrase', disabled: true, required: false },
    ],
  },
  {
    id: 'SRTCaller',
    name: 'SRT Caller',
    isCreated: true,
    type: 1,
    fields: [
      { id: 'host', name: 'Stream host', disabled: false, required: true },
      { id: 'port', name: 'Port', disabled: false, required: true },
      {
        id: 'exportStreamId',
        name: 'Stream ID',
        disabled: false,
        required: false,
      },
      { id: 'key', name: 'Passphrase', disabled: false, required: false },
    ],
  },
  {
    id: 'Facebook',
    name: 'Facebook',
    isCreated: false,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
  {
    id: 'Youtube',
    name: 'Youtube',
    isCreated: false,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
  {
    id: 'Twitch',
    name: 'Twitch',
    isCreated: false,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
  {
    id: 'Telegram',
    name: 'Telegram',
    isCreated: false,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
  {
    id: 'Instagram',
    name: 'Instagram',
    isCreated: false,
    fields: [
      { id: 'url', name: 'Streaming URL', required: true },
      { id: 'key', name: 'Streaming Key', required: false },
    ],
  },
].filter(Boolean);

class Export extends React.Component {
  state = {
    showOptions: false,
    currentOption: 'RTMP',
    currentSource: '',
    currentDisabled: false,
    exportList: [],
    formFields: {},
    focusedPrimaryId: '',
    exportLabels: null,
    isOpenLabel: { id: null, isOpen: false, value: '', isOpenEdit: false },
    showEditModal: false,
    isShowText: false,
    enableOmniSrtH265Mode: enableOmniSrtH265Mode, // enable or disable SRT_AMS mode (change settings: bitrate, 2Mb/s and codec, H.265)
    isEnabledDeleteAll: true,
  };

  componentDidMount(prevProps, prevState) {
    this.fetchItems(false, this.props.focusedPrimaryId);
    this.props.setFetchExportListFn(this.fetchItems);
  }

  setNewBroadcastSettings = (
    omniExportSRT,
    broadcastSettings,
    notificationText,
    type
  ) => {
    const generateMessage = (codec, bitrate, text) => {
      return `${text}, Codec was changed to ${codec} and Bitrate was change to ${bitrate}Mb/s`;
    };
    const { isExist, isEnabled, isEnabledAnyAnotherExport } = omniExportSRT;
    const isPrimaryConnected =
      Object.keys(broadcastSettings).length === 0 ? false : true;
    if (isPrimaryConnected) {
      if (type === 'delete' && isExist) {
        broadcastSettings.codec = 'H.264';
        broadcastSettings.maxBitrate = '5';
        this.props.showNotification(
          'export',
          generateMessage(
            broadcastSettings.codec,
            broadcastSettings.maxBitrate,
            notificationText
          )
        );
      } else if (type === 'delete-all') {
        if (isEnabledAnyAnotherExport) {
          this.props.showNotification('export', notificationText);
        } else {
          broadcastSettings.codec = 'H.264';
          broadcastSettings.maxBitrate = '5';
          this.props.showNotification(
            'export',
            generateMessage(
              broadcastSettings.codec,
              broadcastSettings.maxBitrate,
              notificationText
            )
          );
        }
      } else {
        if (isExist) {
          if (isEnabled) {
            if (isEnabledAnyAnotherExport) {
              broadcastSettings.codec = 'H.264';
              broadcastSettings.maxBitrate = '5';
              this.props.showNotification(
                'export',
                generateMessage(
                  broadcastSettings.codec,
                  broadcastSettings.maxBitrate,
                  notificationText
                )
              );
            } else {
              broadcastSettings.codec = 'H.265';
              broadcastSettings.maxBitrate = '2';
              this.props.showNotification(
                'export',
                generateMessage(
                  broadcastSettings.codec,
                  broadcastSettings.maxBitrate,
                  notificationText
                )
              );
            }
          } else {
            broadcastSettings.codec = 'H.264';
            broadcastSettings.maxBitrate = '5';
            this.props.showNotification(
              'export',
              generateMessage(
                broadcastSettings.codec,
                broadcastSettings.maxBitrate,
                notificationText
              )
            );
          }
        } else {
          this.props.showNotification('export', notificationText);
        }
      }
    }
    return broadcastSettings;
  };

  checkIsExistOmniSRT = (exportList, notificationText, type) => {
    if (this.state.enableOmniSrtH265Mode) {
      const broadcastSettings = { ...this.props.broadcastSettings };
      const { camera, streamType } = broadcastSettings;
      if (
        camera !== 'external' &&
        streamType !== 'record' &&
        streamType !== 'record-and-play' &&
        !this.props.isPrimaryBroadcasting
      ) {
        const exportSRT =
          exportList === 'state'
            ? this.state.exportList.find((exp) => exp.type === 8)
            : Array.isArray(exportList)
              ? exportList.find((exp) => exp.type === 8)
              : exportList;
        const isEnabled = exportSRT && exportSRT.isEnabled;
        const isExistAnyAnotherExport = this.state.exportList.filter(
          (exp) => exp.type !== 8
        );
        const isEnabledAnyAnotherExport = !!(
          isExistAnyAnotherExport.length !== 0 &&
          isExistAnyAnotherExport.filter((exp) => exp.isEnabled === true).length
        );
        const omniExportSRT = {
          isExist: Array.isArray(exportSRT) ? !!exportSRT.length : !!exportSRT,
          isEnabled,
          isEnabledAnyAnotherExport,
        };
        const newBroadcastSettings = this.setNewBroadcastSettings(
          omniExportSRT,
          broadcastSettings,
          notificationText,
          type
        );
        this.props.clickSettingsApply(newBroadcastSettings);
      } else {
        this.props.showNotification('export', notificationText);
      }
    } else {
      this.props.showNotification('export', notificationText);
    }
  };

  checkBroadcastSettings = (settings) => {
    const settingsLength = Object.keys(settings).length;
    if (settingsLength !== 0) {
      this.handleUpdateAmsSrt();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.focusedPrimaryId !== this.props.focusedPrimaryId) {
      this.fetchItems(false, this.props.focusedPrimaryId);
    }

    if (this.props.isExportDisplay !== prevProps.isExportDisplay) {
      this.fetchItems(this.state.showOptions, this.state.focusedPrimaryId);
      this.setState({
        isOpenLabel: {
          ...this.state.isOpenLabel,
          isOpen: false,
          isOpenEdit: false,
        },
      });
    }

    this.setIsEnabledDeleteAll(prevState);

    // Check if we have exports that are not equal to type 8 and 7

    if (this.state.exportList.length !== 0 && !this.props.isExistExport) {
      this.props.setIsExistExport(true);
    }
    if (this.state.exportList.length === 0 && this.props.isExistExport) {
      this.props.setIsExistExport(false);
    }
  }

  setShowEditModal = (showEditModal) => {
    this.setState({ showEditModal: showEditModal });
  };

  setIsEnabledDeleteAll = (prevState) => {
    if (
      this.state.exportList.length !== 0 &&
      this.state.exportList !== prevState.exportList
    ) {
      if (keepAmsExports && (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)) {
        const isExistOtherExportsTypes = this.state.exportList.filter(
          (item) => item.type !== 8 && item.type !== 7
        );
        if (
          isExistOtherExportsTypes.length !== 0 &&
          !this.state.isEnabledDeleteAll
        ) {
          this.setState({ ...this.state, isEnabledDeleteAll: true });
        } else if (
          isExistOtherExportsTypes.length === 0 &&
          this.state.isEnabledDeleteAll
        ) {
          return this.setState({ ...this.state, isEnabledDeleteAll: false });
        }
      } else if (!this.state.isEnabledDeleteAll) {
        return this.setState({ ...this.state, isEnabledDeleteAll: true });
      }
    }
  };

  checkIExistAnyExport = (data) => {
    if (
      data &&
      data.length === 0 &&
      useAmsExportConvertAndLogic &&
      (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)
    ) {
      return this.handleCreateExport();
    }
    return;
  };

  generateAMSExport = async (config) => {
    try {
      const data = {
        exportStreamId: '',
        isEnabled: true,
        protocol: config.protocol,
        src: 0,
        type: config.type,
      };

      const response = await axios({
        method: 'post',
        url: config.url,
        withCredentials: true,
        data,
      });

      if (response.status === 200) {
        // If we create export type 7 or 8 then we should update codec type to H.264
        this.props.handleUpdateCodecType('H.264');
      }
      if (config.type === 8) {
        this.fetchItems(false, this.state.focusedPrimaryId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  getAMSExportInfo = async (focusedPrimaryId) => {
    try {
      const response = await axios({
        method: 'get',
        url: EXPORT_APIS.getAMSExport(focusedPrimaryId),
        withCredentials: true,
      });

      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  checkIsEnabledExports = async (data, focusedPrimaryId) => {
    const isAnyExportsExist = data.filter(
      (exp) => exp.type !== 8 && exp.type !== 7
    );
    if (isAnyExportsExist.length !== 0) {
      const isRTMPEnabled = data
        .filter(
          (exp) =>
            (exp.protocol === 'rtmp' || exp.protocol === 'rtmps') &&
            exp.type !== 7
        )
        .filter((exp) => exp.isEnabled === true);

      if (isRTMPEnabled.length === 0) {
        const amsExport = await this.getAMSExportInfo(focusedPrimaryId);
        if (amsExport.amsExportProtocol !== 'SRT') {
          this.generateAMSExport({
            url: EXPORT_APIS.generateExportSRT(focusedPrimaryId),
            type: 8,
            protocol: 'srt',
          });
        }
      }
    } else if (data.length !== 0 && data[0].type !== 8) {
      this.generateAMSExport({
        url: EXPORT_APIS.generateExportSRT(focusedPrimaryId),
        type: 8,
        protocol: 'srt',
      });
    }
  };

  checkIsRTMPExport = () => {
    const isRTMPExist = this.state.exportList
      .filter(
        (exp) =>
          (exp.protocol === 'rtmp' || exp.protocol === 'rtmps') &&
          exp.type !== 7
      )
      .filter((exp) => exp.isEnabled === true);

    return isRTMPExist.length > 0;
  };

  //-----------------------------API functions------------------------------------------------------------------------

  fetchItems = async (show, focusedPrimaryId) => {
    // this.setState({ exportList: [],
    // 	showOptions: false});
    try {
      const res = await axios({
        method: 'get',
        url: EXPORT_APIS.getExportList(focusedPrimaryId),
        withCredentials: true,
      });
      const data = res.data['exports'];
      if (show) {
        this.setState({
          focusedPrimaryId: focusedPrimaryId,
          currentDisabled: true,
          exportList: data,
          showOptions: true,
          showEditModal: false,
        });
      } else {
        this.setState({
          focusedPrimaryId: focusedPrimaryId,
          formFields: {},
          currentDisabled: false,
          exportList: data,
          showOptions: data.length === 0,
          showEditModal: false,
        });
      }
      this.getExportLabel(focusedPrimaryId);
      if (keepAmsExports && (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)) {
        this.checkIExistAnyExport(data);
      }
      if (useAmsExportConvertAndLogic && (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)) {
        this.checkBroadcastSettings(this.props.broadcastSettings);
        this.checkIsEnabledExports(data, focusedPrimaryId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  showExports = async (focusedPrimaryId) => {
    try {
      const res = await axios({
        method: 'get',
        url: EXPORT_APIS.getExportList(focusedPrimaryId),
        withCredentials: true,
      });
      let data = res.data['exports'];
      this.setState({
        focusedPrimaryId: focusedPrimaryId,
        formFields: {},
        currentDisabled: false,
        exportList: data,
        showOptions: data.length === 0,
        showEditModal: false,
      });
      if (data.length === 0) {
        this.props.showNotification(
          'show',
          `No exports found for ${this.props.primaryName}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  getExportLabel = async (focusedPrimaryId) => {
    try {
      const res = await axios({
        method: 'get',
        url: EXPORT_APIS.getExportLabels(focusedPrimaryId),
        withCredentials: true,
      });
      this.setState({
        exportLabels: res.data.exports,
        isOpenLabel: {
          ...this.state.isOpenLabel,
          isOpenEdit: false,
          value: '',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  setExportLabel = async (focusedPrimaryId, exportsItemId, labelText) => {
    try {
      const data = JSON.stringify({
        exportLabel: labelText,
      });
      const response = await axios({
        method: 'post',
        url: EXPORT_APIS.setExportLabel(focusedPrimaryId, exportsItemId),
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data,
      });
      if (response.status === 200 || response.status === 201) {
        this.getExportLabel(focusedPrimaryId);
      } else {
        this.props.showNotification('error', 'Server error');
      }
    } catch (error) {
      this.props.showNotification('error', 'Server error');
    }
  };

  deleteExportLabel = async (focusedPrimaryId, exportsItemId) => {
    try {
      await axios({
        method: 'delete',
        url: EXPORT_APIS.deleteExportLabel(focusedPrimaryId, exportsItemId),
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.getExportLabel(focusedPrimaryId);
      this.setState({
        isOpenLabel: {
          ...this.state.isOpenLabel,
          isOpen: false,
          isOpenEdit: false,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleCreateExport = async () => {
    try {
      const data = {
        exportStreamId: '',
        isEnabled: true,
        protocol: 'srt',
        src: 0,
        type: 8,
      };
      const res = await axios({
        method: 'post',
        url: EXPORT_APIS.generateExportSRT(this.state.focusedPrimaryId),
        withCredentials: true,
        data: data,
      });
      this.checkCodecType();
      this.fetchItems(true, this.state.focusedPrimaryId);
    } catch (error) {
      this.props.showNotification('export', error.response.data.message);
    }
  };

  checkCodecType = () => {
    const broadcastSettings = { ...this.props.broadcastSettings };
    if (broadcastSettings.codec === 'H.264') {
      broadcastSettings.codec = 'H.265';
      this.props.clickSettingsApply(broadcastSettings);
    }
  };

  handleUpdateCodecType = (type) => {
    const broadcastSettings = { ...this.props.broadcastSettings };
    if (
      Object.keys(broadcastSettings).length !== 0 &&
      broadcastSettings.codec !== type
    ) {
      broadcastSettings.codec = type;
      this.props.clickSettingsApply(broadcastSettings);
    }
  };

  handleUpdateAmsSrt = () => {
    const { exportList } = this.state;
    if (exportList.length !== 0) {
      const isExistOmniSRT = exportList.find((exp) => exp.type === 8);
      const isEnabledAnyAnotherExport = exportList.find(
        (exp) => exp.type !== 8 && exp.isEnabled
      );
      const isChangedCodecManually = isExistUser(this.state.focusedPrimaryId);
      if (!isChangedCodecManually) {
        if (!!isExistOmniSRT && !isEnabledAnyAnotherExport) {
          this.handleUpdateCodecType('H.265');
        } else {
          this.handleUpdateCodecType('H.264');
        }
      }
    }
  };

  //------------------------------------------------------------------------------------------------------------------

  handleClick = () => {
    this.setState({
      showOptions: !this.state.showOptions,
      formFields: {},
      currentOption: '',
      currentSource: '',
    });
  };

  handleChoice = (id) => {
    this.setState({
      currentOption: id,
      currentDisabled: false,
      formFields: {},
    });
  };

  handleInputChange = (e) => {
    let value = e.target.value;
    let formFields = { ...this.state.formFields };
    formFields[e.target.id] = value;
    this.setState({ formFields });
  };

  handleRefreshExport = (focusedPrimaryId) => {
    this.fetchItems(false, focusedPrimaryId);
    this.setState({
      isOpenLabel: {
        ...this.state.isOpenLabel,
        isOpen: false,
        value: '',
        isOpenEdit: false,
      },
    });
  };

  handleBuildUrl = (item) => {
    let url = `${item.protocol}://`;
    switch (item.type) {
      case 0:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 9:
      case 10:
      case 11:
        url += item.host;
        if (item.port) {
          url += `:${item.port}`;
        }
        if (!item.endpoint) {
          url += `/${item.key}`;
        }
        if (item.endpoint) {
          url += `/${item.endpoint}/${item.key}`;
        }
        break;
      case 1:
        url += `${item.host}:${item.port}`;
        if (item.key || item.exportStreamId) {
          if (item.key) {
            url += `?pbkeylen=32&passphrase=${item.key}`;
          }
          if (!item.key && item.exportStreamId) {
            url += `?streamid=${item.exportStreamId}`;
          }
          if (item.exportStreamId && item.key) {
            url += `&streamid=${item.exportStreamId}`;
          }
        }
        url += '&pkt_size=1000&mode=caller';
        break;
      case 2:
        url += `${item.host}:${item.port}?`;
        if (item.key) {
          url += `pbkeylen=32&passphrase=${item.key}&`;
        }
        url += 'pkt_size=1000&mode=listener';
        break;
      case 8:
        url += `${item.host}:${item.port}?streamid=${item.exportStreamId}&pkt_size=1000&mode=caller`;
        break;
      default:
        url = '';
    }
    return url;
  };

  onUrlClick = (item) => {
    const exportType = this.defineExportType(item.type);
    const exportName = exportType.name;
    const url = this.handleBuildUrl(item);
    navigator.clipboard.writeText(url);
    this.props.showNotification(
      'url_clipboard',
      exportName + ' URL copied to clipboard'
    );
  };

  handleCopyField = (value, name) => {
    navigator.clipboard.writeText(value ? value : null);
    this.props.showNotification(
      'value_clipboard',
      `${name} copied to clipboard`
    );
  };

  handleCopyEmdedCode = (event) => {
    let playerSRC = '';
    const exportsItemId = parseInt(event.currentTarget.value);
    const item = this.state.exportList.find(
      (elem) => elem.id === exportsItemId
    );

    playerSRC = `${item.player}&playOrder=hls`;

    const playingProtocol =
      item.playingProtocols &&
      item.playingProtocols.find(
        (protocol) =>
          protocol.type ===
          amsPlayerPlayOrderProtocolType
      );

    if (playingProtocol) {
      playerSRC = playingProtocol.playerURL;
    }

    const iframeCode = `<iframe width="560" height="315" src="${playerSRC}" frameborder="0" allowfullscreen></iframe>`;
    navigator.clipboard.writeText(iframeCode);
    this.props.showNotification(
      'value_clipboard',
      'iFrame code successfully copied to the clipboard'
    );
  };

  handleDelete = async (exportsItemId, isChange) => {
    if (isNaN(exportsItemId)) {
      return;
    }
    try {
      await axios({
        method: 'delete',
        url: EXPORT_APIS.deleteExport(
          this.state.focusedPrimaryId,
          exportsItemId
        ),
        withCredentials: true,
      });
      const currentExport = this.state.exportList.find(
        (exp) => exp.id === exportsItemId
      );
      if (currentExport.type === 8) {
        const notificationText = `Omni Player SRT - Export was deleted successfully`;
        this.checkIsExistOmniSRT(currentExport, notificationText, 'delete');
      }
    } catch (error) {
      console.log(error.response);
      this.props.showNotification(
        'cannot_delete',
        error.response ? error.response.data.message : 'Server error'
      );
    }
    if (!isChange) {
      this.fetchItems(false, this.state.focusedPrimaryId);
    }
  };

  handleToggle = async (event) => {
    const exportsItemId = parseInt(event.target.value);
    if (isNaN(exportsItemId)) {
      return;
    }
    const exportItem = this.state.exportList.find(
      (item) => item.id === exportsItemId
    );
    exportItem.isEnabled = event.target.checked;

    try {
      const amsExport = await this.getAMSExportInfo(
        this.state.focusedPrimaryId
      );
      if (useAmsExportConvertAndLogic && (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)) {
        if (
          (exportItem.protocol === 'rtmp' || exportItem.protocol === 'rtmps') &&
          exportItem.type !== 7 &&
          exportItem.isEnabled &&
          amsExport.amsExportProtocol !== 'RTMP'
        ) {
          await this.generateAMSExport({
            url: EXPORT_APIS.generateExportRTMP(this.state.focusedPrimaryId),
            type: 7,
            protocol: 'rtmp',
          });
        }
      }

      await axios({
        method: 'put',
        url: EXPORT_APIS.putExport(this.state.focusedPrimaryId, exportsItemId),
        withCredentials: true,
        data: exportItem,
      });
      if (exportItem.type === 8) {
        if (exportItem.isEnabled) {
          const notificationText = `Omni Player SRT - Export was enabled successfully`;
          this.checkIsExistOmniSRT(exportItem, notificationText);
        } else {
          const notificationText = `Omni Player SRT - Export was disabled successfully`;
          this.checkIsExistOmniSRT(exportItem, notificationText);
        }
      }
    } catch (error) {
      this.props.showNotification('cannot_enable', error.response.data.message);
    }
    this.fetchItems(false, this.state.focusedPrimaryId);
  };

  defineExportType = (type) => {
    const data = {
      id: '',
      name: '',
      isEdit: undefined,
    };
    switch (type) {
      case 0:
        return {
          ...data,
          id: 'RTMP',
          name: 'Custom RTMP',
          isEdit: true,
          iconType: 'common',
        };
      case 1:
        return {
          ...data,
          id: 'SRTCaller',
          name: 'SRT Caller',
          isEdit: true,
          iconType: 'common',
        };
      case 2:
        return {
          ...data,
          id: 'SRTListener',
          name: 'SRT Listener',
          isEdit: true,
          iconType: 'common',
        };
      case 3:
        return {
          ...data,
          id: 'Facebook',
          name: 'Facebook Profile',
          isEdit: false,
          iconType: 'facebook',
        };
      case 4:
        return {
          ...data,
          id: 'Facebook',
          name: 'Facebook Page',
          isEdit: false,
          iconType: 'facebook',
        };
      case 5:
        return {
          ...data,
          id: 'Facebook',
          name: 'Facebook Group',
          isEdit: false,
          iconType: 'facebook',
        };
      case 6:
        return {
          ...data,
          id: 'Youtube',
          name: 'YouTube Channel',
          isEdit: false,
          iconType: 'youtube',
        };
      case 7:
        return {
          ...data,
          id: 'OmnistreamLivePlayerRTMP',
          name: 'LIVE OMNI - RTMP',
          isEdit: true,
          iconType: 'omniPlayer',
        };
      case 8:
        return {
          ...data,
          id: 'OmnistreamLivePlayerSRT',
          name: 'LIVE OMNI - SRT',
          isEdit: true,
          iconType: 'omniPlayer',
        };
      case 9:
        return {
          ...data,
          id: 'Twitch',
          name: 'Twitch Live',
          isEdit: false,
          iconType: 'Twitch',
        };
      case 10:
        return {
          ...data,
          id: 'Telegram',
          name: 'Telegram',
          isEdit: false,
          iconType: 'Telegram',
        };
      case 11:
        return {
          ...data,
          id: 'Instagram',
          name: 'Instagram',
          isEdit: false,
          iconType: 'Instagram',
        };
      default:
        return {
          ...data,
          id: '',
          name: 'Type export not defined',
          isEdit: false,
          iconType: 'common',
        };
    }
  };

  handleShowModal = (event) => {
    const exportsItemId = parseInt(event.currentTarget.value);
    const currentOption = event.target.getAttribute('name');
    const item = this.state.exportList.find(
      (item) => item.id === exportsItemId
    );
    const exportName = item && this.defineExportType(item.type).name;
    const isEditExport = item && this.defineExportType(item.type).isEdit;
    const buttonRole = {
      id: event.currentTarget.id,
      currentOption: currentOption,
    };

    if (buttonRole.id === 'copy') {
      const url = this.handleBuildUrl(item);

      const labelValue = url;
      this.handleChoice(buttonRole.currentOption);
      return this.props.setExportModalData(
        exportsItemId,
        this.handleDelete,
        this.handleHideModal,
        buttonRole,
        this.state.exportList,
        this.fetchItems,
        this.deleteLabelItem,
        this.setShowEditModal,
        exportName,
        labelValue,
        this.handleCopyField,
        true,
        undefined,
        this.checkIsExistOmniSRT
      );
    }

    if (buttonRole.id === 'delete-all-exports') {
      const labelValue = null;
      this.handleChoice(buttonRole.currentOption);
      return this.props.setExportModalData(
        exportsItemId,
        this.handleDelete,
        this.handleHideModal,
        buttonRole,
        this.state.exportList,
        this.fetchItems,
        this.deleteLabelItem,
        this.setShowEditModal,
        exportName,
        labelValue,
        this.handleCopyField,
        true,
        undefined,
        this.checkIsExistOmniSRT
      );
    }

    if (buttonRole.id === 'play_video' || buttonRole.id === 'share_video') {
      const labelValue = null;

      const item = this.state.exportList.find(
        (elem) => elem.id === exportsItemId
      );

      let videoUrl = `${item.player}&playOrder=hls`;

      const playingProtocol =
        item.playingProtocols &&
        item.playingProtocols.find(
          (protocol) =>
            protocol.type ===
            amsPlayerPlayOrderProtocolType
        );

      if (playingProtocol) {
        videoUrl = playingProtocol.playerURL;
      }

      this.handleChoice(buttonRole.currentOption);
      return this.props.setExportModalData(
        exportsItemId,
        this.handleDelete,
        this.handleHideModal,
        buttonRole,
        this.state.exportList,
        this.fetchItems,
        this.deleteLabelItem,
        this.setShowEditModal,
        exportName,
        labelValue,
        this.handleCopyField,
        true,
        videoUrl
      );
    }

    if (buttonRole.id === 'create-export') {
      const labelValue = null;
      this.handleChoice(buttonRole.currentOption);

      const isExistAmsExport = this.state.exportList.find(
        (exp) => exp.type === 7 || exp.type === 8
      );

      const isRTMPExist = this.checkIsRTMPExport();

      const exportType =
        isExistAmsExport && this.defineExportType(isExistAmsExport.type).id;

      if (exportType === currentOption) {
        return this.props.showNotification('export', 'Export already exists');
      } else if (
        !isRTMPExist &&
        currentOption === 'OmnistreamLivePlayerRTMP' &&
        useAmsExportConvertAndLogic &&
        (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni)
      ) {
        if (this.props.isUserFreeTrial) {
          return this.props.showNotification(
            'export',
            'Please enable RTMP export in order to use OMNI LIVE RTMP'
          );
        } else if (this.props.isUserBasic || this.props.isUserLiveOmni) {
          return this.props.showNotification(
            'export',
            'Your license type is not supporting switching Omni Live Player exports'
          );
        }
      }

      return this.props.setExportModalData(
        exportsItemId,
        this.handleDelete,
        this.handleHideModal,
        buttonRole,
        this.state.exportList,
        this.fetchItems,
        this.deleteLabelItem,
        this.setShowEditModal,
        exportName,
        labelValue,
        this.handleCopyField,
        true,
        undefined,
        this.checkIsExistOmniSRT,
        this.handleUpdateCodecType,
        this.props.isUserFreeTrial,
        this.props.isUserBasic,
        this.props.isUserLiveOmni,
        this.props.isUserGuest,
      );
    }
    if (isEditExport || buttonRole.id === 'delete-label') {
      const label =
        this.state.exportLabels !== null
          ? this.state.exportLabels.find((label) => label.id === exportsItemId)
          : null;
      const labelValue = label ? label['exportLabel'] : '';
      if (!item.isEnabled && buttonRole.id === 'edit') {
        return this.props.showNotification(
          'edit',
          'Cannot edit offline export, for edit please switch toggle on'
        );
      } else {
        return this.props.setExportModalData(
          exportsItemId,
          this.handleDelete,
          this.handleHideModal,
          buttonRole,
          this.state.exportList,
          this.fetchItems,
          this.deleteLabelItem,
          this.setShowEditModal,
          exportName,
          labelValue,
          this.handleCopyField,
          true,
          undefined,
          this.checkIsExistOmniSRT
        );
      }
    } else {
      if (buttonRole.id === 'edit') {
        return this.props.showNotification(
          'edit',
          `Update export type: ${exportName} is not allowed`
        );
      }
      if (buttonRole.id === 'delete') {
        return this.props.showNotification(
          'delete',
          `Delete export type: ${exportName} is not allowed`
        );
      }
    }
  };

  handleHideModal = (isEdit) => {
    this.props.setExportModalData(false);
    if (!isEdit) {
      this.setShowEditModal(false);
    }
  };

  //----------------------------------LABEL FUNCTIONS-------------------------------------------------------

  setOpenLabel = (id) => {
    if (
      this.state.isOpenLabel.id === id &&
      this.state.isOpenLabel.isOpenEdit === true
    ) {
      return;
    } else {
      if (
        this.state.isOpenLabel.id === id &&
        this.state.isOpenLabel.isOpen === true
      ) {
        this.setState({
          isOpenLabel: {
            ...this.state.isOpenLabel,
            id: id,
            isOpen: false,
            isOpenEdit: false,
          },
        });
      } else {
        this.setState({
          isOpenLabel: {
            ...this.state.isOpenLabel,
            id: id,
            isOpen: true,
            value: '',
            isOpenEdit: false,
          },
        });
      }
    }
  };

  saveLabelItem = (id) => {
    if (!this.state.isOpenLabel.value) {
      if (
        !this.state.isOpenLabel.isOpenEdit &&
        !this.state.isOpenLabel.isOpen
      ) {
        this.props.showNotification('save', 'You should press the edit button');
      } else {
        this.props.showNotification('save', 'Label could not be empty');
      }
    } else {
      this.setExportLabel(
        this.state.focusedPrimaryId,
        id,
        this.state.isOpenLabel.value
      );
    }
  };

  cancelLabelItem = () => {
    this.setState({
      isOpenLabel: {
        ...this.state.isOpenLabel,
        isOpen: false,
        value: '',
        isOpenEdit: false,
      },
    });
  };

  editLabelItem = (id) => {
    const exportLabels = this.state.exportLabels;
    const isExistLabel =
      exportLabels === null
        ? false
        : exportLabels.find((elem) => elem.id === id);
    const label = isExistLabel ? isExistLabel.exportLabel : false;
    if (label) {
      if (
        this.state.isOpenLabel.isOpenEdit &&
        this.state.isOpenLabel.id === id
      ) {
        return;
      } else {
        this.setState({
          isOpenLabel: {
            ...this.state.isOpenLabel,
            id: id,
            isOpenEdit: true,
            value: '',
          },
        });
      }
    } else {
      this.setState({
        isOpenLabel: {
          ...this.state.isOpenLabel,
          id: id,
          isOpenEdit: true,
          value: '',
        },
      });
    }
  };

  deleteLabelItem = (id) => {
    this.deleteExportLabel(
      this.state.focusedPrimaryId,
      id,
      this.state.isOpenLabel.value
    );
  };

  handleInputChangeLabel = (e) => {
    const value = e.target.value.slice(0, 30);
    this.setState({ isOpenLabel: { ...this.state.isOpenLabel, value: value } });
  };

  //------------------------------------------------------------------------------------------------

  handleShowText = () => {
    this.setState({ isShowText: !this.state.isShowText });
  };

  handleResetShowText = () => {
    this.setState({ isShowText: false });
  };

  renderLabelItem = (id) => {
    const exportLabels = this.state.exportLabels;
    const isOpenEdit =
      this.state.isOpenLabel.id === id
        ? this.state.isOpenLabel.isOpenEdit
        : false;
    const isExistLabel =
      exportLabels === null
        ? false
        : exportLabels.find((elem) => elem.id === id);
    const label = isExistLabel ? isExistLabel.exportLabel : false;
    const item = label
      ? isExistLabel
      : this.state.isOpenLabel.id === id
        ? this.state.isOpenLabel
        : null;

    return (
      <Fragment>
        {label ? (
          <div>
            {isOpenEdit ? (
              <Fragment>
                <label className="col-form-label modal-field-label">
                  Export Label
                </label>
                <div className="label-container">
                  <div className="input-group input-group-sm mb-2 mr-1">
                    <input
                      id="displayed-name-input"
                      onChange={this.handleInputChangeLabel}
                      value={this.state.isOpenLabel.value}
                      className="form-control ml-0"
                    />
                  </div>
                  <div className="label-buttons-container">
                    <button
                      className="p2 material-icons label-icon-size export-label-btns"
                      id="label"
                      value={item.id.toString()}
                      onClick={() => this.saveLabelItem(item.id)}
                    >
                      save
                    </button>
                    <button
                      type="button"
                      className="p2 material-icons label-icon-size export-label-btns"
                      id="delete-label"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      delete
                    </button>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <label className="col-form-label modal-field-label">
                  Export Label
                </label>
                <div className="label-container">
                  <div className="input-group input-group-sm mb-2 mr-1">
                    <input
                      id="displayed-name-input"
                      onChange={this.handleInputChangeLabel}
                      value={label}
                      className="form-control ml-0"
                      disabled={true}
                    />
                  </div>
                  <div className="label-buttons-container">
                    <button
                      type="button"
                      className="p2 edit-btn material-icons export_btns_padd label-edit-btn"
                      id="label"
                      value={item.id.toString()}
                      onClick={() => this.editLabelItem(item.id)}
                    >
                      edit
                    </button>
                    <button
                      type="button"
                      className="p2 delete-btn material-icons export_btns_padd label-delete-btn"
                      id="delete-label"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      delete_outline
                    </button>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        ) : (
          item &&
          item.isOpen && (
            <Fragment>
              <div>
                <label className="col-form-label modal-field-label">
                  Export Label
                </label>
                <div className="label-container">
                  <div className="input-group input-group-sm mb-2 mr-1">
                    <input
                      id="displayed-name-input"
                      onChange={this.handleInputChangeLabel}
                      type="text"
                      value={this.state.isOpenLabel.value}
                      className="form-control ml-0"
                    />
                  </div>
                  <div className="label-buttons-container">
                    <button
                      className="p2 material-icons label-icon-size export-label-btns"
                      id="label"
                      value={item.id.toString()}
                      onClick={() => this.saveLabelItem(item.id)}
                    >
                      save
                    </button>
                    <button
                      className="p2 material-icons label-icon-size export-label-btns"
                      id="label"
                      value={item.id.toString()}
                      onClick={() => this.cancelLabelItem(item.id)}
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        )}
      </Fragment>
    );
  };

  checkFieldKey = (id, formFields) => {
    if (id === 'key') {
      if (!formFields[id]) {
        return '';
      }
      if (this.state.isShowText) {
        return formFields[id]
          ? formFields[id].split('').length > 40
            ? formFields[id].slice(0, 40) + '...'
            : formFields[id]
          : '';
      } else {
        if (formFields[id]) {
          return formFields[id].split('').length > 10
            ? '••••••••••••••••••••••••••••••••••••••••'
            : formFields[id] || '';
        }
      }
    } else {
      return formFields[id] || '';
    }
  };

  renderUrlItem = (item) => {
    const exportType = this.defineExportType(item.type);
    const foundExportMethod = EXPORT_METHODS.find((method) => method.id === exportType.id);

    if (!foundExportMethod) {
      console.error(`Export method with id ${exportType.id} not found`);
      return null;
    }

    const typeExportFields = foundExportMethod.fields;
    const formFields = [];

    if (
      exportType.id === 'RTMP' ||
      exportType.id === 'Facebook' ||
      exportType.id === 'Youtube' ||
      exportType.id === 'Twitch' ||
      exportType.id === 'Telegram' ||
      exportType.id === 'Instagram' ||
      exportType.id === 'OmnistreamLivePlayerRTMP'
    ) {
      if (item.protocol === 'rtmp') {
        formFields['url'] = `rtmp://${item.host}${item.port ? `:${item.port}` : ''
          }${item.endpoint ? `/${item.endpoint}` : ''}`;
        formFields['key'] = `${item.key ? item.key : ''}`;
      }
      if (item.protocol === 'rtmps') {
        formFields['url'] = `rtmps://${item.host}${item.port ? `:${item.port}` : ''
          }${item.endpoint ? `/${item.endpoint}` : ''}`;
        formFields['key'] = `${item.key ? item.key : ''}`;
      }
    } else {
      typeExportFields.forEach((elem) => {
        formFields[elem.id] = item[elem.id];
      });
    }

    const exportLabels = this.state.exportLabels;
    const isOpenEdit =
      this.state.isOpenLabel.id === item.id
        ? this.state.isOpenLabel.isOpenEdit
        : false;
    const isExistLabel =
      exportLabels === null
        ? false
        : exportLabels.find((elem) => elem.id === item.id);
    const label = isExistLabel ? isExistLabel.exportLabel : false;

    const labelTitle = label
      ? isOpenEdit
        ? 'Add export label'
        : 'Export label exist'
      : 'Add export label';
    return (
      <div id={item.id} key={item.id} className="list-item-container mb-1">
        <div className="export-heading">
          <div className="export-heading-text-container">
            <div className="export-image-container">
              <img
                alt="icon export"
                src={
                  exportType.iconType === 'facebook'
                    ? facebookIcon
                    : exportType.iconType === 'youtube'
                      ? youtubeIcon
                      : exportType.iconType === 'omniPlayer'
                        ? omniPlayerIcon
                        : exportType.iconType === 'Twitch'
                          ? twitchIcon
                          : exportType.iconType === 'Telegram'
                            ? telegramIcon
                            : exportType.iconType === 'Instagram'
                              ? instagramIcon
                              : common_export_icon
                }
              ></img>
            </div>
            <b>{`${exportType.name}`}</b>
          </div>
          <div className="controllers-container controllers-btns">
            <div className="pt-1" style={{ display: 'flex' }}>
              {(exportType.id === 'OmnistreamLivePlayerRTMP' ||
                exportType.id === 'OmnistreamLivePlayerSRT') && (
                  <Fragment>
                    <button
                      className="p2 copy-btn material-icons-outlined export_btns_padd mi-1"
                      id="share_video"
                      title="Share Live Stream"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      share
                    </button>
                    <button
                      className="p2 copy-btn material-icons export_btns_padd mi-1"
                      id="copy_embed_code"
                      title="Copy embed code"
                      value={item.id.toString()}
                      onClick={this.handleCopyEmdedCode}
                    >
                      <img
                        className="copy-embed-icon"
                        src="/images/share_icon.svg"
                        alt="copy_embed_code"
                      />
                    </button>
                    <button
                      className="p2 copy-btn material-icons-outlined export_btns_padd mi-1"
                      id="play_video"
                      title="Open video"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      <img
                        className="play-video-icon"
                        src="/images/play_icon.svg"
                        alt="play video"
                      />
                    </button>
                  </Fragment>
                )}
              <button
                className="p2 copy-btn material-icons export_btns_padd mi-1"
                id="copy"
                title="Copy full export url"
                value={item.id.toString()}
                onClick={
                  item.type === 2
                    ? this.handleShowModal
                    : () => this.onUrlClick(item)
                }
              >
                content_copy
              </button>
              <button
                className="p2 label-btn material-icons export_btns_padd mi-1"
                id="label"
                title={labelTitle}
                value={item.id.toString()}
                onClick={() => this.setOpenLabel(item.id)}
              >
                {label ? (isOpenEdit ? 'label' : 'label_off') : 'label'}
              </button>
              {exportType.id !== 'OmnistreamLivePlayerRTMP' &&
                exportType.id !== 'OmnistreamLivePlayerSRT' && (
                  <Fragment>
                    <button
                      className="p2 edit-btn material-icons export_btns_padd mi-1"
                      id="edit"
                      title="Edit export"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      {exportType.id === 'SRTListener' ? 'autorenew' : 'edit'}
                    </button>
                  </Fragment>
                )}
              {exportType.id !== 'OmnistreamLivePlayerRTMP' &&
                exportType.id !== 'OmnistreamLivePlayerSRT' ? (
                <Fragment>
                  <button
                    className="p2 delete-btn material-icons export_btns_padd mi-1"
                    id="delete"
                    title="Delete export"
                    value={item.id.toString()}
                    onClick={this.handleShowModal}
                  >
                    delete_outline
                  </button>
                  <Toggle
                    className="toggle_btn_pad export_btns_padd p2"
                    checked={item.isEnabled}
                    value={item.id.toString()}
                    onChange={this.handleToggle}
                  />
                </Fragment>
              ) : (
                (!keepAmsExports ||
                  (!this.props.isUserFreeTrial && !this.props.isUserBasic && !this.props.isUserLiveOmni)) && (
                  <Fragment>
                    <button
                      className="p2 delete-btn material-icons export_btns_padd mi-1"
                      id="delete"
                      title="Delete export"
                      value={item.id.toString()}
                      onClick={this.handleShowModal}
                    >
                      delete_outline
                    </button>
                    <Toggle
                      className="toggle_btn_pad export_btns_padd p2"
                      checked={item.isEnabled}
                      value={item.id.toString()}
                      onChange={this.handleToggle}
                    />
                  </Fragment>
                )
              )}
            </div>
          </div>
        </div>
        {typeExportFields.map((field) => (
          <Fragment key={field.name}>
            {exportType.id === 'SRTCaller' &&
              (field.id === 'port' || field.id === 'exportStreamId') ? (
              <>
                {field.id === 'port' && (
                  <div className="SRTCaller-input-container">
                    {typeExportFields
                      .filter(
                        (elem) =>
                          elem.id === 'port' || elem.id === 'exportStreamId'
                      )
                      .map((field) => (
                        <Fragment key={field.name}>
                          <div className="SRTCaller-input">
                            <label className="col-form-label modal-field-label">
                              {field.name}
                            </label>
                            <div className="input-group input-group-sm mb-2">
                              <input
                                id={field.id}
                                className={`form-control input-custom ${field.id === 'key' && 'input-custom-key'
                                  }`}
                                value={this.checkFieldKey(field.id, formFields)}
                                disabled={true}
                                type={
                                  field.id === 'key'
                                    ? this.state.isShowText
                                      ? 'text'
                                      : 'password'
                                    : 'text'
                                }
                              />
                              <div className="btn-input-export-container">
                                {field.id === 'key' && (
                                  <button
                                    className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input-export copy-btn-input-fields"
                                    id="copy"
                                    value={formFields[field.id] || ''}
                                    onClick={this.handleShowText}
                                  >
                                    {this.state.isShowText
                                      ? 'visibility'
                                      : 'visibility_off'}
                                  </button>
                                )}
                                <button
                                  className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input-export copy-btn-input-fields"
                                  id="copy"
                                  value={formFields[field.id] || ''}
                                  onClick={() =>
                                    this.handleCopyField(
                                      formFields[field.id],
                                      field.name
                                    )
                                  }
                                >
                                  content_copy
                                </button>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                )}
              </>
            ) : (
              <>
                <label className="col-form-label modal-field-label">
                  {field.name}
                </label>
                <div className="input-group input-group-sm mb-2">
                  <input
                    id={field.id}
                    className={`form-control input-custom ${field.id === 'key' && 'input-custom-key'
                      }`}
                    value={this.checkFieldKey(field.id, formFields)}
                    disabled={true}
                    type={
                      field.id === 'key'
                        ? this.state.isShowText
                          ? 'text'
                          : 'password'
                        : 'text'
                    }
                  />
                  <div className="btn-input-export-container">
                    {field.id === 'key' && (
                      <button
                        className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input-export copy-btn-input-fields"
                        id="copy"
                        value={formFields[field.id] || ''}
                        onClick={this.handleShowText}
                      >
                        {this.state.isShowText
                          ? 'visibility'
                          : 'visibility_off'}
                      </button>
                    )}
                    <button
                      className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input-export copy-btn-input-fields"
                      id="copy"
                      value={formFields[field.id] || ''}
                      onClick={() =>
                        this.handleCopyField(formFields[field.id], field.name)
                      }
                    >
                      content_copy
                    </button>
                  </div>
                </div>
              </>
            )}
          </Fragment>
        ))}
        {this.renderLabelItem(item.id)}
      </div>
    );
  };

  render() {
    // const title = 'Export Type';
    const { showOptions, focusedPrimaryId } = this.state;
    // const currentChoise = EXPORT_METHODS.find(
    // 	method => method.id === this.state.currentOption
    // );

    const exportList = this.state.exportList
      ? this.state.exportList.map((item) => this.renderUrlItem(item))
      : 'No Items To Show';

    const formObjectExport = () => {
      const reducer = (previousValue, currentValue) =>
        previousValue || currentValue;
      const exportTypes = {
        type: [
          ...new Set(
            this.state.exportList.map(
              (item) => this.defineExportType(item.type).id
            )
          ),
        ],
        isEnabled: [],
      };
      exportTypes.type.forEach((type) => {
        const existExport = this.state.exportList
          .filter((elem) => this.defineExportType(elem.type).id === type)
          .map((item) => item.isEnabled);
        const isExistExport = existExport.reduce(reducer);
        exportTypes.isEnabled[type] = isExistExport;
      });
      return exportTypes;
    };

    const defineToggleOption = (id) => {
      const exportOption = formObjectExport().type.find((elem) => elem === id);
      if (exportOption) {
        if (formObjectExport().isEnabled[exportOption] === true) {
          return (
            <div className="export-list-option-light-active" name={id}></div>
          );
        } else {
          return (
            <div className="export-list-option-light-disable" name={id}></div>
          );
        }
      } else {
        return null;
      }
    };

    const formatString = (name, id, expression) => {
      const boldWord = name.replace(expression, '');
      const lastIndex = name.lastIndexOf(' ');
      name = name.substring(0, lastIndex);
      return (
        <>
          {name}
          <span name={id} className="show-export-list-text-bold">
            &nbsp;{boldWord}
          </span>
        </>
      );
    };

    const formatFontOptionsButton = (name, id) => {
      if (
        id === 'OmnistreamLivePlayerRTMP' ||
        id === 'OmnistreamLivePlayerSRT'
      ) {
        return formatString(name, id, /LIVE OMNI - /g);
      } else if (id === 'RTMP') {
        return formatString(name, id, /Custom /g);
      } else return name;
    };

    return (
      <div className="export-container mt-1">
        {focusedPrimaryId ? (
          <div style={{ height: '100%' }}>
            {showOptions ? null : (
              <div className="export-list-item-wrapper">
                {/* <div className="export-list-item-container pl-3 pr-3">{exportList.reverse()} */}
                <div className="export-list-item-container pl-3 pr-3">
                  <Carousel
                    exportList={this.state.exportList}
                    exportComponent={exportList.reverse()}
                    handleResetShowText={this.handleResetShowText}
                  />
                </div>
                <div className="list-bottom-container pl-3 pr-3">
                  <div className="playlist_remove">
                    <button
                      disabled={!this.state.isEnabledDeleteAll}
                      className={
                        keepAmsExports &&
                          (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni) &&
                          exportList.length === 1
                          ? 'btn list-bottom-buttons playlist_remove_button remove_cursor'
                          : 'btn list-bottom-buttons playlist_remove_button'
                      }
                      id="delete-all-exports"
                      value="delete-all-exports"
                      onClick={this.handleShowModal}
                    >
                      <span
                        className={
                          keepAmsExports &&
                            (this.props.isUserFreeTrial || this.props.isUserBasic || this.props.isUserLiveOmni) &&
                            exportList.length === 1
                            ? 'material-icons playlist_remove_icon my-tooltip remove_cursor'
                            : 'material-icons playlist_remove_icon my-tooltip'
                        }
                        label="Delete all exports"
                      >
                        delete_sweep
                        {this.state.isEnabledDeleteAll && (
                          <span className="tooltip-text">
                            Delete all exports
                          </span>
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="list-bottom-buttons-container">
                    <button
                      className="btn list-bottom-buttons"
                      onClick={() => this.handleRefreshExport(focusedPrimaryId)}
                    >
                      <div className="list-bottom-buttons-text">
                        <span className="material-icons list-bottom-buttons-icons">
                          refresh&nbsp;
                        </span>
                        <span>REFRESH</span>
                      </div>
                    </button>
                    <button
                      className="btn list-bottom-buttons"
                      onClick={this.handleClick}
                    >
                      <div className="list-bottom-buttons-text">
                        <span className="material-icons list-bottom-buttons-icons">
                          add&nbsp;
                        </span>
                        <span>ADD</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showOptions ? (
              <div className="options-container">
                <div className="container-padd">
                  {EXPORT_METHODS.map((method) =>
                    method.isCreated ? (
                      <div
                        id="create-export"
                        onClick={this.handleShowModal}
                        name={method.id}
                        key={method.id}
                        className="form-check-inline"
                      >
                        <label
                          name={method.id}
                          className={`btn export-list-option-item ${method.id === 'RTMP'
                            ? 'button-rtmp'
                            : method.id === 'OmnistreamLivePlayerRTMP' ||
                              method.id === 'OmnistreamLivePlayerSRT'
                              ? 'button-omniplayer'
                              : 'button-srt'
                            }`}
                        >
                          <div
                            className="export-list-option-text-container"
                            name={method.id}
                          >
                            <div
                              className={`export-list-option-text ${(method.id === 'OmnistreamLivePlayerRTMP' ||
                                method.id === 'OmnistreamLivePlayerSRT') &&
                                'text-omniplayer'
                                }`}
                              name={method.id}
                            >
                              {formatFontOptionsButton(method.name, method.id)}
                            </div>
                            {defineToggleOption(method.id)}
                          </div>
                        </label>
                      </div>
                    ) : null
                  )}
                </div>
                <button
                  className="m-1 btn show-export-list"
                  onClick={() => this.showExports(focusedPrimaryId)}
                >
                  <div className="show-export-list-text">
                    <span className="material-icons">list</span>
                    <span>Export List</span>
                  </div>
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <p className="m-3">No primary selected.</p>
        )}
      </div>
    );
  }
}

Export.propTypes = {
  focusedPrimaryId: PropTypes.number,
  showNotification: PropTypes.func.isRequired,
};

export default Export;
