export const LicensePermissions = {
    CORPORATE: 0,
    VIDEOGRAPHER: 1,
    INFLUENCER: 2,
    FREE_TRIAL: 3,
    MYKEY: 4,
    BASIC: 5,
    LIVE_OMNI: 6,
    GUEST: 7,

    CORPORATE_NAME: "CORPORATE",
    VIDEOGRAPHER_NAME: "VIDEOGRAPHER",
    INFLUENCER_NAME: "INFLUENCER",
    FREE_TRIAL_NAME: "FREE TRIAL",
    MYKEY_NAME: "MYKEY",
    BASIC_NAME: "BASIC",
    LIVE_OMNI_NAME: "LIVE OMNI",
    GUEST_NAME: "GUEST"
};

export const getPermissionNameById = (id) => {
    switch (id) {
        case LicensePermissions.CORPORATE:
            return LicensePermissions.CORPORATE_NAME;
        case LicensePermissions.VIDEOGRAPHER:
            return LicensePermissions.VIDEOGRAPHER_NAME;
        case LicensePermissions.INFLUENCER:
            return LicensePermissions.INFLUENCER_NAME;
        case LicensePermissions.FREE_TRIAL:
            return LicensePermissions.FREE_TRIAL_NAME;
        case LicensePermissions.MYKEY:
            return LicensePermissions.MYKEY_NAME;
        case LicensePermissions.BASIC:
            return LicensePermissions.BASIC_NAME;
        case LicensePermissions.LIVE_OMNI:
            return LicensePermissions.LIVE_OMNI_NAME;
        case LicensePermissions.GUEST:
            return LicensePermissions.GUEST_NAME;
        default:
            return "";
    }
};
