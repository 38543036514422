import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Viewer.css';

import InfoHeader from '../InfoHeader/InfoHeader';
import MiniPreview from '../MiniPreview/MiniPreview';
import AlarmIcon from '../common/AlarmIcon/AlarmIcon';
import Button from '../common/Button/Button';

class Viewer extends React.Component {
	state = {
		isAlarmIconDisplayed: false,
		isAlarmCritical: false,
		alarmTooltipText: '',
		criticalAlarmTooltipText: '',
	};

	_isSchedulerSet = false;
	durationStr = '00:00:00';

	componentDidMount() {
		this.checkStatusesForAlarms(this.props.statuses);

		$('.viewer').fadeIn(500);
	}

	componentDidUpdate(prevProps) {
		const { isBroadcasting, broadcastDetails, statuses } = this.props;

		if (isBroadcasting !== prevProps.isBroadcasting) {
			if (isBroadcasting === true) {
				this.setDurationScheduler();
				this._isSchedulerSet = true;
			} else {
				clearInterval(this._durationScheduler);
				this._isSchedulerSet = false;
				this.durationStr = '00:00:00';
			}
		}

		if (broadcastDetails !== prevProps.broadcastDetails) {
			if (isBroadcasting === true && this._isSchedulerSet === false) {
				this.setDurationScheduler();
				this._isSchedulerSet = true;
			}
		}

		if (statuses !== prevProps.statuses) {
			this.checkStatusesForAlarms(statuses);
		}
	}

	componentWillUnmount() {
		clearInterval(this._durationScheduler);
	}

	setDurationScheduler = () => {
		if (this.props.broadcastDetails !== undefined) {
			this.durationStr = this.props.broadcastDetails.duration;
		}
		this._durationScheduler = setInterval(() => {
			if (this.props.broadcastDetails !== undefined) {
				this.durationStr = this.props.broadcastDetails.duration;
			}
		}, 1000);
	};

	checkStatusesForAlarms = (statuses) => {
		const criticalAlarmIndex = statuses.findIndex(
			(status) => status.Name === 'PortsUnavailable'
		);
		const criticalAlarmTooltipText =
			criticalAlarmIndex > -1
				? statuses[criticalAlarmIndex].Description
				: '';

		const alarmIndex = statuses.findIndex(
			(status) => status.Name === 'DownLinkStatus'
		);
		const alarmTooltipText =
			alarmIndex > -1 ? statuses[alarmIndex].Description : '';

		const isAlarmCritical = criticalAlarmTooltipText ? true : false;
		const isAlarmIconDisplayed =
			alarmTooltipText || criticalAlarmTooltipText ? true : false;

		this.setState({
			alarmTooltipText,
			criticalAlarmTooltipText,
			isAlarmCritical,
			isAlarmIconDisplayed
		});
	};

	handleClick = (e) => {
		const classNameStr = e.target.className.toString();
		if (!classNameStr.includes('name-edit-icon')) {
			this.props.handlePrimaryFocus(this.props.primaryId);
		}
	};

	render() {
		const {
			focusedPrimaryId,
			primaryId,
			primaryName,
			displayedName,
			isActive,
			isBroadcasting,
			broadcastDetails,
			screenSize,
			setDraggedElement,
			handleDrop,
			setDetailsForModal,
			outputAddressId,
			daysToExpire,
			onAttachPrimary,
			onDettachPrimary,
			audioDevices,
			selectedDevice,
			companyType,
			isPrimaryBroadcasting,
			streamType,
			clickStartStop,
			isChangingStartStopInProgress,
            showNotification,
			setShareId,
			setPrimaryToDelete,
			primaryUsers,
			licensePermission,
			licensePermissionId,
			licenseTypeId,
		} = this.props;

		const {
			isAlarmIconDisplayed,
			isAlarmCritical,
			alarmTooltipText,
			criticalAlarmTooltipText
		} = this.state;

		let bitrateStr = '0.0';
		if (broadcastDetails !== undefined) {
			bitrateStr = broadcastDetails.bitrate;
		}
		let iconName;
		if (isPrimaryBroadcasting === true) {
			iconName = 'stop';
		} else {
			switch (streamType) {
				case 'live':
				case 'average':
				case 'best-quality':
					iconName = 'play_arrow';
					break;

				case 'record':
					iconName = 'fiber_manual_record';
					break;

				case 'record-and-play':
					iconName = 'cloud_upload';
					break;

				default:
					iconName = 'play_arrow';
			}
		}

		const innerHeight = window.innerHeight;

		return (

			<div
				className={`viewer mb-1${focusedPrimaryId === primaryId
					? ' bg-info'
					: ''}`}

			>
				<div
					onClick={this.handleClick}
					className="drag-and-drop-container"
					draggable="true"
					onDragStart={() =>
						setDraggedElement({ type: 'primary', id: primaryId })}
					onDragOver={(e) => e.preventDefault()}
					onDrop={() => handleDrop({ type: 'primary', id: primaryId })}
				>
					<InfoHeader
						type="Primary"
						id={focusedPrimaryId}
						primaryId={primaryId}
						name={primaryName}
						displayedName={displayedName}
						isActive={isActive}
						isBroadcasting={isBroadcasting}
						screenSize={screenSize}
						componentLocation="dashboard-input"
						setDetailsForModal={setDetailsForModal}
						outputAddressId={outputAddressId}
						daysToExpire={daysToExpire}
						companyType={companyType}
						showNotification={showNotification}
						setShareId={setShareId}
						setPrimaryToDelete={setPrimaryToDelete}
						primaryUsers={primaryUsers}
						licensePermission={licensePermission}
						licensePermissionId={licensePermissionId}
						licenseTypeId={licenseTypeId}
					/>
					<div className='parent-class'>
						{isAlarmIconDisplayed &&
							<AlarmIcon
								position="absolute"
								tooltipDirection="down"
								criticalLevel={isAlarmCritical ? 2 : 1}
								alarmTooltipText={isAlarmCritical ? criticalAlarmTooltipText : alarmTooltipText}
								alarmIcon="warning"/>
						}

						<MiniPreview
							isInput={true}
							signalId={primaryId}
							isThereSignal={isBroadcasting}
							screenSize={screenSize}
							audioDevices={audioDevices}
							primaryId={primaryId}
							companyType={companyType}
						/>

						<div
							className={`broadcast-info${companyType===0
								? ''
								: '-extended'} font-weight-bold ${isBroadcasting
								? 'visible'
								: 'invisible'}`}
						>
							<span>{bitrateStr} Mb/s</span>
							<span>{this.durationStr}</span>
						</div>
					</div>
				</div>
				{companyType===0 ? (
					<Button
						bsClassSuffix='white'
						iconName={selectedDevice && selectedDevice.primaries[primaryId] !== undefined ? 'headset' : 'headset_off'}
						isDisabled={primaryId && selectedDevice!=='' ? false : true}
						clickHandler={() => {
									if (selectedDevice && selectedDevice.primaries[primaryId] === undefined){
										onAttachPrimary(selectedDevice,primaryId)
									}else{
										onDettachPrimary(selectedDevice,primaryId)
									}
						}}
					/>): (focusedPrimaryId === primaryId ?
					(!isChangingStartStopInProgress ? (
						<Button
							bsClassSuffix={focusedPrimaryId === primaryId ? 'white' : 'black'}
							iconName={iconName}
							clickHandler={clickStartStop}
					/>
					) : (
						<Button
							uniqueId='play-stop-btn'
							showSpinner={true}
							clickHandler={() => {}}
						/>
					)):'')}
			</div>
		);
	}
}

Viewer.propTypes = {
	focusedPrimaryId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
		.isRequired,
	primaryId: PropTypes.number.isRequired,
	isBroadcasting: PropTypes.bool.isRequired,
	statuses: PropTypes.array.isRequired,
	broadcastDetails: PropTypes.object,
	handlePrimaryFocus: PropTypes.func.isRequired,
	setDraggedElement: PropTypes.func.isRequired,
	handleDrop: PropTypes.func.isRequired
};

export default Viewer;
