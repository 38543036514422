import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import './Login.css';

import errorMessages from './errorMessages';

import { APIs } from '../../constants/endPointsURLs';

import InputField from '../common/InputField/InputField';
import Button from '../common/Button/Button';

import { version } from '../../../package.json';

class Login extends React.Component {
  state = {
    companyName: '',
    username: '',
    password: '',
    showPassword: false,
    isFormValid: false,
    isErrorMessageDisplayed: false,
    currentErrorMessage: 'placeholder',
    isLogginIn: false,
  };

  inputCompanyRef = React.createRef();
  inputPasswordRef = React.createRef();

  componentDidMount() {
    this._isMounted = true;
    this.inputCompanyRef.current.focus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFormChange = (e) => {
    if (this._isMounted === false) {
      return;
    }

    if (e.target.id === 'company-name') {
      this.setState({ companyName: e.target.value }, () => {
        this.validateForm();
      });
    } else if (e.target.id === 'username') {
      this.setState({ username: e.target.value }, () => {
        this.validateForm();
      });
    } else if (e.target.id === 'password') {
      this.setState({ password: e.target.value }, () => {
        this.validateForm();
      });
    }
  };

  validateForm = () => {
    const { companyName, username, password } = this.state;
    if (companyName.length >= 2 && username.length >= 2 && password.length >= 6) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
  };

  handleShowPassword = () => {
    this.setState(
      {
        ...this.state,
        showPassword: !this.state.showPassword,
      },
      () => {
        this.inputPasswordRef.current.focus();
      },
    );
  };

  handleLogin = () => {
    this.setState({
      isErrorMessageDisplayed: false,
      isLogginIn: true,
    });
    const { companyName, username, password } = this.state;

    axios({
      method: 'post',
      url: APIs.login,
      data: {
        companyName,
        username,
        password,
      },
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200;
      },
    })
      .then((response) => {
        this.setState({ isLogginIn: false });
        if (response.status === 200) {
          this.props.setLogin(response.data);
        } else if (response.status === 403) {
          this.setState({
            isErrorMessageDisplayed: true,
            currentErrorMessage: errorMessages.credentials,
          });
        } else if (response.status >= 500) {
          this.setState({
            isErrorMessageDisplayed: true,
            currentErrorMessage: errorMessages.serverErr,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        this.setState({
          isErrorMessageDisplayed: true,
          currentErrorMessage: errorMessages.serverNoResponse,
          isLogginIn: false,
        });
      });
  };

  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.state.isFormValid === true) {
        this.handleLogin();
      }
    }
  };

  render() {
    const {
      companyName,
      username,
      password,
      showPassword,
      isFormValid,
      isErrorMessageDisplayed,
      currentErrorMessage,
      isLogginIn,
    } = this.state;

    let logoWidth;
    if (this.props.screenSize === 'large') {
      logoWidth = 250;
    } else if (this.props.screenSize === 'medium') {
      logoWidth = 230;
    } else {
      logoWidth = 200;
    }

    return (
      <div
        className="login-page d-flex flex-column justify-content-center align-items-center"
        onKeyPressCapture={this.handleEnterKeyPress}>
        <div className="login-form jumbotron">
          <div className="logo-image d-flex justify-content-center m-5">
            <img src="./images/logo.svg" alt="Omnistream logo" width={logoWidth} />
          </div>

          <div className="mx-2">
            <div className="form-group">
              <InputField
                uniqueId="company-name"
                labelName="Email / Company Name"
                type="text"
                value={companyName}
                changeHandler={this.handleFormChange}
                reference={this.inputCompanyRef}
              />
            </div>
            <div className="form-group">
              <InputField
                uniqueId="username"
                labelName="Username"
                type="text"
                value={username}
                changeHandler={this.handleFormChange}
              />
            </div>
            <div className="form-group input-container">
              <InputField
                uniqueId="password"
                labelName="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                changeHandler={this.handleFormChange}
                reference={this.inputPasswordRef}
              />
              <span
                className="material-icons custom-element"
                onClick={this.handleShowPassword}
              >
                {showPassword ? 'visibility_off' : 'visibility'}
              </span>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                uniqueId="login-button"
                showSpinner={isLogginIn}
                text={isLogginIn ? 'Logging In..' : 'Login'}
                isDisabled={!isFormValid}
                clickHandler={this.handleLogin}
              />
            </div>

            <div
              className={`error-message alert alert-danger ${isErrorMessageDisplayed ? 'visible' : 'invisible'
                } p-2`}>
              <strong>{currentErrorMessage}</strong>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center version-txt">
          Dashboard Version: {version}
        </div>
        <div className=' justify-content-center site-center-text'>
          Site: {process.env.REACT_APP_HOSTNAME.split('.')[0].toUpperCase()}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  screenSize: PropTypes.string,
  setLogin: PropTypes.func.isRequired,
};

export default Login;
