import { EXPORT_APIS } from "../../../../../constants/endPointsURLs";
import config from '../../../../../dashboard_config.json';

const showAmsExportsAndUseLogic = config.dashboard.exports.amsExportsControl.showAmsExportsAndUseLogic;

export const data = [
    showAmsExportsAndUseLogic
    ? {
        id: 1,
        label: 'LIVE OMNI - SRT',
        name: 'LIVE OMNI - SRT',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.generateExportSRT,
    } : null,
    showAmsExportsAndUseLogic
    ? {
        id: 2,
        label: 'LIVE OMNI - RTMP',
        name: 'LIVE OMNI - RTMP',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.generateExportRTMP,
    } : null,
    {
        id: 3,
        label: 'CUSTOM RTMP',
        text: 'CUSTOM RTMP',
        cbUrl: EXPORT_APIS.setExport,
    },
    {
        id: 4,
        label: 'SRT CALLER',
        text: 'SRT CALLER',
        cbUrl: EXPORT_APIS.setExport,
    },
    {
        id: 5,
        label: 'SRT LISTENER',
        name: 'SRT LISTENER',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.setExport,
    },
].filter(Boolean);
