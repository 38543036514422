export const LicenseType = {
    DAILY: 0,
    WEEKLY: 1,
    TWO_WEEKS: 2,
    MONTHLY: 3,
    YEAR: 4,
    PERPETUAL: 5,

    DAILY_NAME: "DAILY",
    WEEKLY_NAME: "WEEKLY",
    TWO_WEEKS_NAME: "TWO WEEKS",
    MONTHLY_NAME: "MONTHLY",
    YEAR_NAME: "YEAR",
    PERPETUAL_NAME: "PERPETUAL"
};

export const getLicenseTypeById = (licenseTypeId) => {
    switch (licenseTypeId) {
        case LicenseType.DAILY:
            return LicenseType.DAILY_NAME;
        case LicenseType.WEEKLY:
            return LicenseType.WEEKLY_NAME;
        case LicenseType.TWO_WEEKS:
            return LicenseType.TWO_WEEKS_NAME;
        case LicenseType.MONTHLY:
            return LicenseType.MONTHLY_NAME;
        case LicenseType.YEAR:
            return LicenseType.YEAR_NAME;
        case LicenseType.PERPETUAL:
            return LicenseType.PERPETUAL_NAME;
        default:
            return "";
    }
};
